
















import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action, Mutation, State } from 'vuex-class';

import { Spinner, IconButton } from '@components/UI';
import { Post, TwitchPost } from '@components/Posts';

@Component({
    components: { Post, Spinner, TwitchPost, IconButton }
})
export default class Stream extends Vue {
    @Action
    public getFrostyStream: Function;

    @Action
    public loadStream: () => Promise<any>;

    @Mutation
    public updateStreamLayout;

    @State
    public streamSettings;

    @Getter
    public stream: any;

    private loading: boolean = false;
    private observer: IntersectionObserver | null;
    private frostyStream: any = null;

    public async mounted() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.loading) {
                    this.loading = true;
                    this.loadStream().finally(() => this.loading = false);
                }
            })
        });

        this.observer.observe(this.$refs.trigger as HTMLElement);

        /*var res = await this.getFrostyStream();
        if(res) {
            console.log(res);
            this.frostyStream = res.stream;
        }*/
    }

    public beforeDestroy() {
        if (this.observer) {
            this.observer.unobserve(this.$refs.trigger as HTMLElement);
            this.observer.disconnect();
            this.observer = null;
        }
    }

}

